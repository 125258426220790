<template>
    <div>
        <form v-on:submit.prevent="submitEntity" novalidate>
            <div class="field grid align-items-center" v-for="field in fields" :key="field.key">
                <div
                    v-if="field.type === 'text'"
                    class="col-fixed"
                >
                    {{ field.label }}
                </div>

                <template v-else>

                    <label class="col-fixed text-700 mt-1 w-4 block"
                           :class="{'align-self-start pt-1': field.type === 'textarea'}"
                    >
                        <div class="block w-full">
                            <div class="flex align-items-center" v-tooltip.bottom="getTooltipValue(field)">
                                <div>
                                    <div v-html="field.label"></div>
                                    <template v-if="field.unit">
                                        <div class="block w-full mt-1" style="font-size: .8em;">
                                            {{ $t('Unit') }}: {{ field.unit }}
                                        </div>
                                    </template>
                                </div>

                                <div v-if="!getTooltipValue(field).disabled">
                                    <i class="pi pi-info-circle ml-2"></i>
                                </div>
                            </div>
                        </div>
                    </label>
                    <div class="col-fixed mr-4">
                        <div class="flex align-items-center">
                            <Checkbox v-model="notSetValues[field.key]" :binary="true"/>
                            <div class="ml-2">
                                {{ $t('No information / will not be collected') }}
                            </div>
                        </div>
                    </div>
                    <div class="col "
                         :class="{'hidden': notSetValues.hasOwnProperty(field.key) && notSetValues[field.key]}"
                    >
                        <template v-if="field.type === 'date'">
                            <Calendar
                                class="w-full"
                                v-if="field.type === 'date'"
                                v-model="value[field.key]"
                                dateFormat="dd.mm.yy"
                                :locale="de"
                                @date-select="dateSelect($event, field.key)"
                            />
                        </template>

                        <template v-if="field.type === 'multipleDates'">
                            <Calendar
                                class="w-full"
                                selection-mode="multiple"
                                v-if="field.type === 'multipleDates'"
                                v-model="value[field.key]"
                                dateFormat="dd.mm.yy"
                                :locale="de"
                                :showWeek="true"
                            />
                        </template>

                        <InputNumber
                            class="w-full"
                            type="text"
                            required
                            v-if="field.type === 'currency'"
                            v-model="value[field.key]"
                            mode="decimal"
                            currency="EUR"
                            locale="de-DE"
                            :step="1"
                            suffix=" Euro"
                        />

                        <Textarea
                            class="w-full"
                            v-if="field.type === 'textarea'"
                            v-model="value[field.key]"
                            :autoResize="true"
                            rows="5"
                        />

                        <InputText
                            class="w-full"
                            type="text"
                            required
                            v-if="field.type === 'text'"
                            v-model="value[field.key]"
                        />

                        <InputNumber
                            class="w-full"
                            type="text"
                            required
                            v-if="field.type === 'number'"
                            v-model="value[field.key]"
                            showButtons
                            :prefix="field.prefix ? field.prefix : null"
                            :aria-readonly="true"
                        />

                        <small class="p-invalid block mt-2" v-for="message in errors[field.key]" :key="message">
                            {{ message }}
                        </small>
                    </div>
                </template>
            </div>

            <template v-if="value.userCanUpdate">
                <div class="surface-border border-top-1 opacity-50 mb-4 mt-4 py-0 col-12"></div>

                <div class="text-center">
                    <Button type="submit" :label="$t('Save')"></Button>
                </div>
            </template>
        </form>
    </div>
</template>

<script>
    import InternUpdateView from "@/mixin/InternUpdateView";
    import Button from 'primevue/button';
    import InputText from "primevue/inputtext/InputText";
    import InputNumber from "primevue/inputnumber/InputNumber";
    import Textarea from 'primevue/textarea';
    import Calendar from "primevue/calendar";
    import Checkbox from "primevue/checkbox";
    import moment from 'moment';

    export default {
        mixins: [InternUpdateView],
        props: {
            surveyParts: {
                type: [Array, Object],
                required: true,
            },
            fields: {
                type: [Array],
                required: true,
            },
            type: {
                type: String,
                required: true,
            }
        },
        components: {
            Button,
            Calendar,
            InputText,
            InputNumber,
            Textarea,
            Checkbox
        },
        data() {
            return {
                notSetValues: {},
                de: {
                    firstDayOfWeek: 1,
                    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
                    dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
                    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
                    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                    monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
                    today: 'Heute',
                    clear: '-',
                    weekHeader: 'KW'
                },
            }
        },
        mounted() {
            this.loadSurveyPart();
        },
        watch: {
            'surveyParts': {
                handler: function (newVal) {
                    this.loadSurveyPart();
                },
                deep: true
            },
            /*'value': {
                deep: true,
                handler: function (newVal) {
                    Object.keys(newVal).forEach((item) => {
                        if(newVal[item] === null) {
                            this.notSetValues[item] = true;
                        }
                    });
                }
            },*/
            'notSetValues': {
                handler: function (newVal) {
                    Object.keys(newVal).forEach((item) => {
                        if (newVal[item] === true) {
                            this.value[item] = null;
                        }
                    });
                    this.$emit('input', value);
                },
                deep: true
            },
            'value.lastDatesHealthAndSafetyCommitteeMeetings': function (newVal) {
                newVal.forEach((item, index) => {
                    newVal[index] = new Date(item);
                });
            }
        },
        methods: {
            getTooltipValue(field) {
                if (field.hasOwnProperty('tooltip')) {
                    return {
                        value: field.tooltip,
                        disabled: false,
                    }
                }

                let tooltips = {
                    'AU': 'Arbeitsunfähigkeitsbescheinigung',
                    'BEM': 'Betriebliches Eingliederungsmanagement',
                    'BGM': 'Betriebliches Gesundheitsmanagement',
                    'OIM': 'Operational integration management',
                    'OHM': 'Occupational health management',
                }

                let tooltip = {
                    disabled: true,
                }

                Object.keys(tooltips).forEach((item) => {
                    if (field.label.indexOf(item) > -1) {
                        tooltip = {
                            value: item + ': ' + tooltips[item],
                            disabled: false,
                        }
                    }
                });

                return tooltip;
            },
            dateSelect(event, fieldKey) {
                let date = moment(event);
                date.set('hour', 12);
                date.set('minute', 0);
                this.value[fieldKey] = date.format('DD.MM.YYYY');
            },
            getSplittedEndpoint(data) {
                return data.split('/').splice(1);
            },
            getApiEndpoint() {
                return this.getSplittedEndpoint(this.value['@id'])[0];
            },
            loadSurveyPart() {
                let surveyPart = this.surveyParts.filter((item) => {
                    return item.clonedType === this.type;
                });

                let [endpoint, id] = this.getSplittedEndpoint(surveyPart[0]['@id']);

                this.$getFromApi(endpoint, id, (entity) => {
                    this.value = entity;
                })
            }
        }
    }
</script>
