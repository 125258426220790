export default {
  methods: {
    getCreationAction(entity) {
      this.getUpdateAction(entity);
    },
    getUpdateAction(entity) {},
    loadEntity(value) {
      this.$getFromApi(this.getApiEndpoint(), value.id, (entity) => {
        this.value = Object.assign({}, entity);
        this.errors = {};
      });
    },
    submitEntity() {
      this.errors = {};
      this.$sendToApi(
        this.getApiEndpoint(),
        this.value,
        (entity, status, errors) => {
          if (status < 300) {
            this.$emit('update', entity);
            if (status === 201) {
              this.getCreationAction(entity);
            } else {
              this.getUpdateAction(entity);
            }
            this.value = entity;
            this.errors = {};
          } else {
            this.errors = errors;
          }
        })
      ;
    }
  },
  data() {
    return {
      value: {},
      errors: {},
    }
  }
}
